@media screen and (max-width: 640px) {
    section.section.mosaic-section {
        margin: 0
    }
}

.spr-review-reportreview {
    display: none;
}

.collection__description a div,
.collection__description a span,
.collection__description a {
    color: var(--link-color) !important;
}

.text-with-icons [aria-hidden=true] a {
    visibility: hidden;
}


.collection__description div,
.collection__description p,
.collection__description span {
    color: var(--text-color) !important;
}

.cbb-frequently-bought-selector-label-name {
    font-weight: 400 !important;
}

#omnisend-pixel-container img {
    width: 1px;
    height: 1px;
}

/* Paruyr faq page margin */
#shopify-section-template--14168269881388__main {
    margin-bottom: -100px;
}

#mobile_logo {
    display: none;
}

@media screen and (max-width: 640px) {
    .header__logo-image {
        display: none;
    }

    #mobile_logo {
        display: block;
    }
}

/* Paruyr rich text section */
.rich_title_bold {
    font-weight: bold;
}

.rich_title_light {
    font-weight: 400;
}

.rich_text_left {
    text-align: left;
}

.rich_text_center {
    text-align: center;
}

.rich_text_right {
    text-align: right;
}

.rich_text_separator_minus {
    border: none;
}

.rich_text_separator_plus {
    border-top: 2px solid #616161;
    border-bottom: 2px solid #616161;
}

/* Paruyr loyalty slideshow */
.loyalty__slideshow__button__mobile {
    display: none !important;
}

.loyalty__slideshow__button__desk {
    display: block !important;
    width: fit-content;
    border: 3px solid #00807a;
}

.loyalty__slideshow__button__desk:hover {
    color: #fff !important;
    background: #00807a !important;
}

@media screen and (max-width: 640px) {
    .loyalty__slideshow__content-wrapper {
        position: absolute;
        /*     transform: translate(-50%,0%) !important; */
        top: 0 !important;
        padding-top: 10px !important;
    }

    .loyalty__slideshow__button {
        line-height: 28px !important;
    }

    .loyalty__slideshow__button__mobile {
        font-size: 12px;
        position: absolute !important;
        bottom: 20px !important;
        left: 50% !important;
        display: block !important;
        width: 60%;
        padding: 0;
        transform: translate(-50%, 0) !important;
        border: 3px solid #00807a;
    }

    .loyalty__slideshow__button__desk {
        display: none !important;
    }

    .team_button {
        font-size: 12px;
        width: 70%;
        padding: 0;
    }
}

.team__member-pic {
    border: 0;
    border-radius: 13px;
    /*   filter: drop-shadow(0px 100px 80px rgba(0, 0, 0, 0.07)) drop-shadow(0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198)) drop-shadow(0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275)) drop-shadow(0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035)) drop-shadow(0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725)) drop-shadow(0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802)); */
    box-shadow: 0px 18px 70px -41px;
}

.team_button {
    color: #fff;
    border: 3px solid #00807a;
    border-radius: 2px;
    background: #00807a;
}

.team_button:hover {
    color: #616161 !important;
    background: #fff !important;
}

.custom-container {
    display: flex;
    align-items: center;
    flex-direction: column;
}

/* Loyalty rewards */
.lion-header__your-points {
    position: relative;
    width: fit-content;
    margin: 40px 0px 0 20px;
    padding: 10px 30px;
    border: 1px solid #3f2b73;
}

.lion-header__your-points:before {
    position: absolute;
    top: -20px;
    right: 0;
    left: 0;
    width: 80%;
    margin: auto;
    content: "Available points";
    text-align: center;
    color: #fff;
    background-color: #3f2b73;
}

#loyaltylion {
    margin-bottom: -50px;
}

@media screen and (max-width: 640px) {
    #loyaltylion .lion-header__nav {
        flex-direction: column;
    }

    .reward__container__flex {
        flex-direction: column;
    }

    .reward__container__flex .layout {
        margin-bottom: 0;
    }

    .rewards__container {
        padding: 0;
    }

    .lion-header__your-points {
        margin-left: 0;
    }
}

.lion-header__nav {
    padding: 0 !important;
}

#loyaltylion .lion-header .lion-header__nav .lion-header__nav-link:before {
    font-size: 54px;
    display: flex;
    align-items: center;
    width: 40px;
    height: auto !important;
    padding: 0 10px !important;
    transform: scale(0.4);
    background-color: transparent !important;
    -webkit-mask-image: none !important;
}

#loyaltylion .lion-header__nav .lion-header__nav-link--earn:before {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f005";
}

#loyaltylion .lion-header__nav .lion-header__nav-link--redeem:before {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f06b";
}

#loyaltylion .lion-header__nav .lion-header__nav-link--account-history:before {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f1da";
}

#loyaltylion .lion-header__nav .lion-header__nav-link--tiers-overview:before {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f5fd";
}

#loyaltylion .lion-tier-box__header {
    padding-top: 0;
}

#loyaltylion .lion-tier-box__position {
    font-size: 0;
    width: 100%;
    height: 0;
    padding-top: 54.166666666667%;
    border-radius: 13px;
    background-size: 100%;
}

#loyaltylion .lion-tier-box--0 .lion-tier-box__position:first-child {
    background-image: url('https://cdn.shopify.com/s/files/1/1118/8312/files/image_87.jpg?v=1635232520');
}

#loyaltylion .lion-tier-box--1 .lion-tier-box__position:first-child {
    background-image: url('//cdn.shopify.com/s/files/1/1118/8312/files/image_69_f318ed7e-beb2-4359-a3be-06154876c739_400x.jpg?v=1634721333');
}

#loyaltylion .lion-tier-box--2 .lion-tier-box__position:first-child {
    background-image: url('//cdn.shopify.com/s/files/1/1118/8312/files/image_70_400x.jpg?v=1634721344');
}

#loyaltylion .lion-tier-box--3 .lion-tier-box__position:first-child {
    background-image: url('//cdn.shopify.com/s/files/1/1118/8312/files/image_70-1_400x.jpg?v=1634721355');
}

#loyaltylion .lion-tier-box--3 .lion-tier-box__position:first-child {
    background-image: url('//cdn.shopify.com/s/files/1/1118/8312/files/image_70-1_400x.jpg?v=1634721355');
}


.rewards__header {
    margin: 0;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 20px;
    border-bottom: 1px solid var(--border-color);
    background-color: #fdfdfd;
}

#admin-bar-iframe {
    display: none;
}

/*frequently bought toghether styles*/
.cbb-frequently-bought-selector-input {
    position: relative !important;
    display: inline-block !important;
    width: 18px !important;
    height: 18px !important;
    cursor: pointer !important;
    transition: all 0.2s ease-in-out !important;
    vertical-align: -4px !important;
    border: none !important;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 4px !important;
    background-color: white !important;
    -webkit-box-shadow: 0 0 0 10px #00807a inset !important;
    box-shadow: 0 0 0 10px #00807a inset !important;
    appearance: none !important;
}

.cbb-frequently-bought-selector-input:checked:after {
    transform: scale(1) !important;
    opacity: 1 !important;
    webkit-transform: scale(1) !important;
}

.cbb-frequently-bought-selector-input:after {
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    display: block !important;
    width: 10px !important;
    height: 8px !important;
    margin-top: -4px !important;
    margin-left: -5px !important;
    content: "";
    -webkit-transition: all 0.2s ease-in-out 0.1s !important;
    transition: all 0.2s ease-in-out 0.1s !important;
    -webkit-transform: scale(0.2) !important;
    transform: scale(0.2) !important;
    opacity: 0 !important;
    background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/checkout_2020-01-15/checkbox-tick-8f61c047ddf394995dc729e4ed5672dfd5185ceb994e4acca120c3ddc11e4012.png);
    background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/checkout_2020-01-15/checkbox-tick-924f1ffcd2bcf9a29293aa0b640b31f39068d9def7837cb7b2788f7e7e7686af.svg);
}

#main-header {
    font-size: 1em !important;
}

.collection__filter-checkbox h3 {
    display: flex;
}

/* Suren  form and map styled */
.contact__form__flex .form__input-row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0px 0 12px;
}

.contact__form__flex .form__input-row div {
    margin: 0px
}

.contact__form__flex .form__input-row div:first-child {
    margin-bottom: 12px;
}

@media screen and (min-width: 1000px) {
    .contact__form__flex form {
        width: 90%;
    }
}

#map {
    margin-bottom: 24px;
}

#map iframe {
    margin-bottom: 24px

}

#map div {
    margin: 4px 0;
}

#map a {
    color: #BF1872;
}

#map span {
    color: #7f6000;
}

@media screen and (min-width: 1000px) {
    #map {
        width: 45%;
    }
}

@media screen and (max-width: 323px) {
    #map span {
        font-size: 13px;
    }
}

/*== Close ==*/

.reorder__btn {
    line-height: 22px !important;
    padding: 5px 10px !important;
    color: #fff;
    background-color: var(--footer-accent-color);
}

.reorder__btn:hover {
    color: #fff;
    background-color: #2a2a2b;
}

#aa img {
    width: 125px;
    height: 50px;
}

.footer__newsletter-text p {
    display: inline !important;
}

#week-countdown {
    font-weight: 700;
    display: flex;
    justify-content: center;
}

.colon {
    font-size: 36px
    width: 15px;
    text-align: center;
}

.week-countdown__timer {
    font-size: 36px
    margin: 0;
    text-align: center;
}

.week-countdown__text {
    font-size: 12px
    margin-top: -20px;
    padding: 0 10px;
}

/* Modal wrap */
.modal-wrap {
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    width: 100%;

    height: 100%;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    background: rgba(0, 0, 0, 0.7);
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
}

#modal-demo.modal_hide {
    display: none !important;
}

.scroll {
    overflow: hidden;
}

.modal-wrap .modal-container {
    z-index: 100;
    width: 600px;
    max-width: 100%;
    margin: 1em;
    background: #fff;
}

.modal-wrap .modal-container .modal-header, .modal-wrap .modal-container .modal-footer {
    width: 100%;
}

.modal-btn-content {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
    max-width: 360px;
    margin-right: auto;
    margin-left: auto;
    padding: 16px 24px 0;
}

.modal_btn {
    font-size: 12px;
    font-weight: 600;
    line-height: 1.2;
    display: flex;
    justify-content: center;
    padding: 16px 50px;
    cursor: pointer;
    transition: padding .8s cubic-bezier(.19, 1, .22, 1);
    text-align: center;
    text-decoration: none;
    letter-spacing: .1em;
    color: var(--primary-button-text-color);
    border: 1px solid var(--primary-button-background) !important;
    border-radius: 32px;
    background-color: var(--primary-button-background);
}

.btn_agree {
    margin-bottom: 20px;
}

.btn_disagree {
    color: #616161;
    border: 2px solid var(--primary-button-background) !important;
    background-color: transparent;
}

.btn_text {
    font-size: 12px;
    font-weight: 600;
    font-style: normal;
    font-stretch: 100%;
    line-height: 14.4px;
    position: relative;
    text-align: center
    letter-spacing: 1.2px;
}

.modal-wrap .modal-header .modal-title {
    font-size: 16px;
    padding: 15px 20px;
    text-align: center;
}

.modal-wrap .modal-content {
    float: left;
    width: 100%;
    margin: 0;
    padding: 15px 30px;
}

.modal_text_2 {
    margin-bottom: 0;
    padding-top: 0 !important;
}

.modal-wrap .modal-footer {
    margin-top: 20px;
    padding: 25px;
    text-align: center;
    background-color: #e9e9e9;
}

.modal-wrap .modal-footer a {
    text-decoration: underline;
    color: #454545 !important;
}

.modal-wrap .modal-footer p {
    font-size: 12px;
    color: #616161;
}

.modal-wrap .modal-footer a {
    color: #fff;
}

/*arrow*/
.arrow {
    transition: all .5s cubic-bezier(.19, 1, .22, 1);
    opacity: 1;
}

.modal_btn:hover:not(:disabled) .arrow {
    transform: translateX(10px);
    opacity: 1;
}

.button:hover:not(:disabled) .arrow {
    padding-right: 57px;
    padding-left: 43px;
    text-decoration: none;
}

.modal-15-container, .modal-10-container {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 20%);
}

#modal-15-demo, #modal-10-demo {
    z-index: 8;
}

.modal-15-form, .modal-10-form {
    display: flex !important;
    align-items: center !important;
    flex-direction: column !important;
}

#modal-15-btn, #modal-10-btn {
    text-transform: uppercase;
    border-radius: 24px;
    background-color: var(--primary-button-background);
}

#input15 {
    margin-bottom: 0;
}

#modal-10-btn {
    align-self: center;
    margin-bottom: 55px;
}

.modal-10-container {
    height: 430px !important;
    background-image: url("https://cdn.shopify.com/s/files/1/1118/8312/files/VJD-SUbscribe-Web.png?v=1664349272") !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
}

.modal-15-container {
    width: 540px !important;
    height: 570px !important;
    background-image: url("https://cdn.shopify.com/s/files/1/1118/8312/files/web_popup_10.png?v=1699015285") !important;
    background-repeat: no-repeat !important;
    background-size: contain !important;
}

@media screen and (max-width: 480px) {
    .modal-10-container {
        background-image: url("https://cdn.shopify.com/s/files/1/1118/8312/files/VJD-SUbscribe-Mobile.png?v=1664349272") !important;
    }

    .modal-15-container {
        width: 280px !important;
        height: 490px !important;
        padding-top: 5px;
        background-image: url("https://cdn.shopify.com/s/files/1/1118/8312/files/mobile_popup_10.png?v=1699015447") !important;
        background-size: contain !important;
    }
}

/* Announcement bar */
.announcement-bar {
    color: #ffffff;
    background: #3f2b73;
}

/* Custom announcement bar */
.custom_announcement-bar {
    color: #ffffff;
    background: #00807a;
}

.custom_announcement-bar__content {
    font-weight: bold;
    text-decoration: underline;
}

/* shipping-modal */
.shipping-modal-wrap {
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    width: 100%;

    height: 100%;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    background: rgba(0, 0, 0, 0.7);
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
}

.shipping-modal-wrap .shipping-modal-container {
    z-index: 100;
    width: 600px;
    max-width: 100%;
    margin: 1em;
    padding-top: 20px;
    background: #fff;
}

.otherText {
    font-size: 12px;
    color: #BF1872;
}

.otherTextGreen {
    color: green;
}

.shipping-modal-wrap .shipping-modal-container .shipping-modal-header {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}

.shipping-modal_text_1 {
    font-size: 2em;
    font-weight: bold;
    /*   margin-bottom: -30px; */
    text-align: center;
}

.shipping-notice-popup {
    font-size: 15px;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
}

.shipping-modal-btn-content {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.shipping-modal_btn {
    font-size: 12px;
    line-height: 1.2;
    display: flex;
    padding: 10px;
    cursor: pointer;
    text-align: center;
    letter-spacing: .1em;
    text-transform: uppercase;
    color: #000;
    border: 1px solid #458077;
    background-color: #fff;
}

.shipping-modal_btn:first-child {
    margin-right: 15px;
    color: #fff;
    background-color: #458077;
}

.shipping-btn_text {
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    font-stretch: 100%;
    line-height: 14.4px;
    position: relative;
    text-align: center;
    text-transform: uppercase;
}

.shipping-modal-wrap .shipping-modal-header .shipping-modal-title {
    font-size: 16px;
    padding: 15px 20px;
    text-align: center;
}

.shipping-modal-wrap .shipping-modal-content {
    float: left;
    width: 100%;
    margin: 0;
    padding: 15px 30px;
}

.shipping-modal_text_2 {
    margin-bottom: 0;
    padding-top: 0 !important;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    .shipping-modal_text_1 {
        font-size: 1.5em;
    }

    .shipping-notice-popup {
        font-size: 0.9em;
    }

    .shipping-btn_text {
        font-size: 11px;
        letter-spacing: 1.1px;
    }

    .shipping-modal_btn {
        padding: 5px 5px;
    }

    .otherText {
        letter-spacing: 4px;
    }

    .otherTextSecond {
        margin-right: 2px;
        margin-left: 2px;
    }
}


/* mosaic tablet Pro */
#tablet_mosaic {
    display: none;
}

@media (min-width: 481px) and (max-width: 790px) {
    #mosaic {
        display: none;
    }

    #tablet_mosaic {
        display: block;
    }
}

.product-label--promocode {
    background: #bf1834;
}

.product_discount {
    display: flex;
    flex-wrap: wrap;
}

.product-block-list__item--description ul {
    margin-bottom: 0.7em
}

.jdgm-carousel__item-container {
    padding: 15px;
    background: white;
}

.apps_container {
    padding: 0 !important;
}

.jdgm-carousel-wrapper {
    width: 100% !important;
}

.jdgm-carousel-item__reviewer-name.jdgm-ellipsis, .jdgm-carousel-item__review-title {
    opacity: 1 !important;
    color: #000 !important;
}

/* Mosaic  */
/* @media screen and (min-width: 1000px){
  .mosaic--two-columns .mosaic__column:first-child {
      flex-grow: 1;
  }
}

@media screen and (max-width: 999px){
  .mosaic--two-columns .mosaic__column:first-child .custom-mosaic__mosaic__item>:first-child, .mosaic--three-columns .mosaic__column:nth-child(2) .custom-mosaic__mosaic__item>:first-child {
      min-height: 164px;
  }
}
  */
/*
@media screen and (max-width: 999px){
  .mosaic--two-columns .mosaic__column:first-child .mosaic__item>:first-child, .mosaic--three-columns .mosaic__column:nth-child(2) .mosaic__item>:first-child {
      min-height: 164px;
  }
} */

/*  .custom-mosaic__mosaic__item>:first-child {
    min-height: 164px;
} */
#trustpilot-widget-trustbox-0-wrapper {
    max-width: 1480px !important;
    margin: auto !important;
}

@media screen and (min-width: 1000px) {
    .hiddenViewAll {
        display: none;
    }
}

.price-match-wrapper {
    display: flex;
}

.price-match {
    width: 96px;
    margin-left: auto;
}

.price-match img {
    width: 96px;
    height: 96px;
}

@media screen and (max-width: 640px) {
    .price-match {
        position: relative;
        top: -54px;
    }
}

.cbb-frequently-bought-add-button {
    border-radius: 24px;
}

.jdgm-write-rev-link[role="button"] {
    border-radius: 28px;
    background-color: var(--primary-button-background);
}

.jdgm-rev-widg__summary {
    margin-bottom: 10px !important;
}

/* Sur for left position add to cart button */

.product-form__info-item--quantity .product-form__info-content {
    display: block;
    margin-left: -52px;
}

.product-form__payment-container > * {
    min-width: 148px;
}

@media screen and (min-width: 398px) {
    .product-form__payment-container {
        margin-top: -74px;
        margin-left: 170px;
    }

    .product-form__button-unavailable {
        max-width: 212px;
        margin-top: 80px;
        margin-left: -168px;
    }
}

@media screen  and  (min-width: 641px) {
    .product-form__info-item--quantity .product-form__info-content {
        margin-left: -54.81px;
    }

    .product-form__payment-container {
        margin-top: -83px;
    }

    .product-form__button-unavailable {
        margin-top: 82px;
    }
}

.quantity-selector--product .quantity-selector__value {
    border: none;
}

.quantity-selector {
    border-radius: 22px;
}

.modal .shopify-payment-button {
    margin-left: 0px;
}

/* End */

/* Sur product hr element */
.cbb-frequently-bought-container {
    margin-top: 42px !important;
    border: none !important;
    border-top: solid 1px var(--border-color) !important;
}

.product-form .cbb-frequently-bought-container {
    padding-top: 20px !important;
}

@media screen and (min-width: 641px) {
    .product-form .cbb-frequently-bought-container {
        margin-top: 46px !important;
        padding-top: 24px !important;
    }
}


.product-item__action-button.button.button--small.button--primary,
.product-item__action-button.button.button--small.button--ternary.hidden-phone,
.product-item__action-button.button.button--small.button--disabled{
    border-radius: 22px
}


.product-form__add-button.button.button--primary,
.product-form__add-button.button.button--disabled,
.form__submit.form__submit--tight.button.button--primary,
.form__submit.form__submit--tight.button.button--primary {
      border-radius: 24px
}

.block-swatch__item{
        border-radius: 24px;
}

.product-form__info-item--quantity .product-form__info-content {
    display: block;
    margin-left: -52px;
}

.product-form__payment-container > * {
    min-width: 148px;
}

@media screen and (min-width: 398px) {
    .product-form__payment-container {
        margin-top: -74px;
        margin-left: 170px;
    }

    .product-form__button-unavailable {
        max-width: 212px;
        margin-top: 80px;
        margin-left: -168px;
    }
}

@media screen  and  (min-width: 641px) {
    .product-form__info-item--quantity .product-form__info-content {
        margin-left: -54.81px;
    }

    .product-form__payment-container {
        margin-top: -83px;
    }

    .product-form__button-unavailable {
        margin-top: 82px;
    }
}

.quantity-selector--product .quantity-selector__value {
    border: none;
}

.quantity-selector {
    border-radius: 22px;
}

.modal .shopify-payment-button {
    margin-left: 0px;
}

.search-bar__submit
{
  border-radius: 0 22px 22px 0;
}

.search-bar__top{
    border-radius: 22px;
}
.h1 {
    font-size: 18px;
}

.h2 {
    font-size: 16px;
}

.h3 {
    font-size: 14px;
}

.jdgm-rev-widg__headerh2 .jdgm-rev-widg__title {
    font-size: 18px !important;
}

@media screen and (min-width: 641px) {
    .h1 {
        font-size: 20px;
    }

    .h2 {
        font-size: 18px;
    }

    .h3 {
        font-size: 16px;
    }

    .jdgm-rev-widg__header h2.jdgm-rev-widg__title {
        font-size: 18px !important;
    }
}

.page__sub-header {
    display: block;
}

.breadcrumb__list {
    overflow: scroll;
    white-space: nowrap;
}

.breadcrumb__list::-webkit-scrollbar {
    display: none;
}

.jdgm-verified-badge--style-vintage .jdgm-verified-badge__wrapper {
    width: 96px !important;
}

.jdgm-verified-badge--style-vintage.jdgm--last-level .jdgm-verified-badge__total {
    font-size: 12px !important;
    top: 26% !important;
}

.jdgm-verified-badge--style-vintage .jdgm-verified-badge__text {
    font-size: 9px !important;
}

.jdgm-verified-badge--style-vintage.jdgm--last-level .jdgm-verified-badge__stars {
    top: 34.5% !important;
}

.jdgm-verified-badge--style-vintage .jdgm-verified-badge__stars .jdgm-star {
    font-size: 7px !important;
}

@media screen and (min-width: 641px) {
    .footer__wrapper {
        padding-bottom: 20px;
    }

    .block-swatch__item {
        border-radius: 22px;
    }

    .footer__aside-item--copyright:not(:only-child) {
        margin-top: 10px;
    }
}

.page__content .sitemapper-list {
    font-size: calc(var(--base-text-font-size) - (var(--default-text-font-size) - 15px));
    margin-bottom: 90px;
}

.faq__group-title {
    margin-bottom: 10px;
    padding: 0;
}

.faq__group-title:after {
    display: block;
    padding: 10px;
    content: "";
    border-bottom: 1px solid #61616161;
}

.faq__group .heading {
    text-align: unset;
}

.faq__item {
    padding: 0 0 10px 0;
    background-color: rgba(22, 15, 38, 0);
}

.faq__item:after {
    display: block;
    padding-top: 10px;
    content: "";
    border-bottom: solid 1px #61616161;
}

.faq__question {
    font-weight: 400;
}


.faq__group-title.heading {
    padding: 0;
}

.faq__question.heading.h4 {
    font-size: 14px;
}

@media screen and (min-width: 641px) {
    .faq__group-title {
        padding: 0 30px;
    }
}


.product-block-list__item--description h1,
.product-block-list__item--description h2,
.product-block-list__item--description h3,
.product-block-list__item--description h4,
.product-block-list__item--description h5,
.product-block-list__item--description h6 {
    font-size: 18px;
}

.product-block-list__item--description h3 {
    margin-top: 0.8em
}

.product-block-list__item--description h4 {
    margin-top: 0.6em
}
.jdgm-rev-widg{
    background-color: var(--secondary-background)
}
.jdgm-widget-actions-wrapper
a.jdgm-write-rev-link{
    text-align: center;
    line-height: 48px;
    font-weight: var(--text-font-bolder-weight);
    font-size: var(--base-text-font-size);
    cursor: pointer;
    border-radius: 24px;
    padding: 0 30px;
    transition: background .25s ease-in-out,color .25s ease-in-out,box-shadow .25s ease-in-out;
    display: inline-block;
    position: relative; 
    background: var(--primary-button-background);
    color: var(--primary-button-text-color);
}

/* Custom announcement bar */
.custom_announcement-bar {
  background: #00807a;
  color: #ffffff;
}
.custom_announcement-bar__content {
  font-weight: bold;
  text-decoration: underline;
}

/* Nicotine Warning */
.nicotine-warning {
    font-size: 16px;
    line-height: 22px;
    background: #fff;
    border: 3px solid #000;
    color: #000;;
/*     font-family: Montserrat; */
    font-weight: 600;
    text-align: center;
    z-index: 1010;
    font-style: normal;
    font-variant-ligatures: normal;
    font-variant-caps: normal;
    font-variant-numeric: normal;
    font-variant-east-asian: normal;
}
.intl-text {
    margin: 0;
    color: #000;
    font-size: 16px;
    line-height: 45px;
    text-align: center;
    display: block;
    font-weight: 600;
}

@media (max-width: 767px) {
  .intl-text {
      line-height: 1.5;
      padding: 5px 0;
   }
}
   
@media only screen and (min-width: 768px){
    .nicotine-warning {
        font-size: 16px;
    }
}
   
   @media only screen and (max-width: 768px){
  .nicotine-warning.product-description{
    margin-bottom:18px;
  }
}

.expandable-content h2 {
    font-weight: 700 !important;
}
.expandable-content p, .expandable-content span {
    color: #616161 !important;
}
.expandable-content a,
.expandable-content a span,
.expandable-content span>a,
.expandable-content span>strong>a,
.expandable-content p>a,
.expandable-content p>span>a,
.expandable-content p>a>span,
.expandable-content p>strong>a,
.expandable-content p>span>strong>span>a,
.expandable-content p>span>a>span,
.expandable-content p>span>span>span>a,
.expandable-content p>span>span>a>span,
.expandable-content p>span>span>a,
.expandable-content p>strong>span>a>span,
.expandable-content p>strong>span>span>a,
.expandable-content p>strong>a>span,
.expandable-content p>span>a>span>strong{
    color: var(--link-color) !important;
}


.button.button--primary.button--full,
.button.button--primary,
.button.button--secondary{
    border-radius: 24px
}

.quantity-selector{
  border: 2px solid var(--border-color);
}

/* Suren  form and map styled */
.contact__form__flex .form__input-row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0px 0 12px;
}

.contact__form__flex .form__input-row div {
    margin: 0px
}

.contact__form__flex .form__input-row div:first-child {
    margin-bottom: 12px;
}

@media screen and (min-width: 1000px) {
    .contact__form__flex form {
        width: 90%;
    }
}

#map {
    margin-bottom: 24px;
}

#map iframe {
    margin-bottom: 24px

}

#map div {
    margin: 4px 0;
}

#map a {
    color: #BF1872;
}

#map span {
    color: #7f6000;
}

@media screen and (min-width: 1000px) {
    #map {
        width: 45%;
    }
}

@media screen and (max-width: 323px) {
    #map span {
        font-size: 13px;
    }
}

.rich_title_bold {
 font-weight: bold; 
}
.rich_title_light {
  font-weight: 400;
}
.rich_text_left {
  text-align: left;
}
.rich_text_center {
  text-align: center;
}
.rich_text_right {
  text-align: right;
}
.rich_text_separator_minus {
  border: none;
}
.rich_text_separator_plus {
  border-top: 2px solid #616161;
  border-bottom: 2px solid #616161;
}

p.heading.h1.rich_text_paragraph.rich_title_bold {
  padding: 20px 0;
  border-top: 1px solid silver; 
  border-bottom: 1px solid silver;
  color: #00807a;
}

.container--narrow--mini {
  max-width: 500px;
}

.loyalty__slideshow__button__mobile {
  display: none !important; 
}
.loyalty__slideshow__button__desk {
  display: block !important;
  width: fit-content;
  border: 3px solid #00807a;
}
.loyalty__slideshow__button__desk:hover {
  background: #00807a !important;
  color: #fff !important;
}

@media screen and (max-width: 640px) {
  .loyalty__slideshow__content-wrapper {
    padding-top: 10px !important;
/*     transform: translate(-50%,0%) !important; */
    top: 0 !important;
    position: absolute;
  }
  .loyalty__slideshow__button {
    line-height: 28px !important;
  }
  .loyalty__slideshow__button__mobile {
    display: block !important; 
    position: absolute !important;
    transform: translate(-50%,0) !important;
    bottom: 20px !important;
    width: 60%;
    left: 50% !important;
    font-size: 12px;
    padding: 0;
    border: 3px solid #00807a;
  }
  .loyalty__slideshow__button__desk {
    display: none !important; 
  }
  .team_button {
    width: 70%;
    font-size: 12px;
    padding: 0;
  }
}
.team__member-pic {
  border: 0;
  border-radius: 13px;
/*   filter: drop-shadow(0px 100px 80px rgba(0, 0, 0, 0.07)) drop-shadow(0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198)) drop-shadow(0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275)) drop-shadow(0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035)) drop-shadow(0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725)) drop-shadow(0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802)); */
  box-shadow: 0px 18px 70px -41px;
  width: 100%;
  padding: 0px;
}

.team_button {
 border: 3px solid #00807a;
  background: #00807a;
  color:#fff;
  border-radius: 2px;
}
.team_button:hover {
  background: #fff !important;
  color: #616161 !important;
}
.custom-container {
 display: flex;
  flex-direction: column;
  align-items: center;
}

.button {
    border-radius: 24px;
}

.team__member {
    padding: 0 0 20px;
    border: 1px solid var(--border-color);
    border-radius: 13px;
    background: #e4eeef;
}
@media screen and (min-width: 1280px){
.team__member{
    width: calc(33.3333% - 30px);
 }
}
.team__promotion {
  width: auto;
  line-height: normal;
}

.team__promotion p:after {
    content: "";
    display: inline-block;
    height: .5em;
    vertical-align: bottom;
    width: 100%;
    margin-left: 5px;
    border-top: 1px solid silver;
}
.team_button{
  border-radius: 28px;
}

.cancellable-reorder a {
    background-color: var(--primary-button-background);
    display: block;
    color: var(--primary-button-text-color);
    text-align: center;
    border-radius: 28px;
    padding: 4px 16px;
}

.feature_vendors {
  display: block;
    max-width: 70%;
    position: relative;
    margin-bottom: 10px;
    transition: transform .3s;
  
}
 .feature_vendors:hover { 
   transform: scale(1.1);
 } 
.feature_vendors img{
  max-width: 70%;
}

#feature-list {
    display: flex;
    flex-wrap: wrap;
}
.feature_vendor_heading {
  margin-bottom: 0px;
  font-size: 15px;
}

.subcategory-item {
  padding: 0 !important;
  font-size: 13px;
  margin: 0 auto; 
}
.Firstletter {
 font-size: 16px;
  font-weight: bold;
}
.Firstletter:after {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background-color: silver;
    margin-bottom: 10px;
}

@media only screen and (max-width: 600px) {
  .feature_subcategory-item {
    max-width: 100%;
    margin: 0 auto;
  }
  .feature_vendors {
    max-width: 100%;
   }
  .subcategory-item {
    margin-right: 0px;
  }
  .feature_vendors img {
    max-width: 80%;
  }
}

@media screen and (max-width: 640px){
    section.section.mosaic-section {
        margin:0
    }
}
.spr-review-reportreview {
    display: none;
}

.collection__description a div,
.collection__description a span,
.collection__description a {
    color: var(--link-color) !important;
}

.text-with-icons [aria-hidden=true] a{
    visibility: hidden;
}


.collection__description div,
.collection__description p,
.collection__description span {
    color: var(--text-color) !important;
}

.cbb-frequently-bought-selector-label-name {
  font-weight: 400 !important;
}

#omnisend-pixel-container img {
    width: 1px;
    height: 1px;
}

.product-block-list__item--description .rte h3,
.product-block-list__item--description .rte h4,
.product-block-list__item--description .rte h5{
    font-size: 15px;
    margin-top: .7em;
    margin-bottom: .7em;
    line-height: 1.75;
    text-transform: none;
}
@media (max-width: 640px) {
.footer__title.heading h6 {
  margin-bottom: 0px ;
}
}

.article__inner{
  max-width: unset;
}


.jdgm-medals-wrapper .jdgm-medals__container {
  padding-bottom: 18px !important;
}






